/**
 * zg-getProductInfo.js
 *
 * Requests the information for products.
 * Can request either a single product, a collection of them or a all products in a category
 *
 *
 * @author: David Pocina <dpocina[at]kooomo[dot]com>
 */

/* global DEBUG, handlebarsTemplates */

/**
 * @event document#zg-error Generic error. Used by 2002-zg-notifier.js to display the error
 * @type {object}
 * @property {string} eventType - Typology of event.
 * @property {string} message - The error message.
 */

/**
 * @event zg.getProductInfo.productCreated Products rendered using the handlebars template specified in 'productsTemplate'
 * @type {object}
 * @property {string} item - Html of the products
 * @property {object} options - options of the plugin and the ajax request
 * @property {object} product - json response by ajax
 */

/**
 * @event zg.getProductInfo.start Execute before sending the AJAX request for get product info
 * @type {null}
 */

/**
 * @event zg.getProductInfo.complete AJAX request for get product info completed
 * @type {null}
 */

/**
 * @event zg.getProductInfo.error AJAX request for get product info make error
 * @type {null}
 */

/**
 * @event zg.getProductInfo.success AJAX request for get product info make success
 * @type {object}
 * @property {object} products - json with products object
 * @property {object} filters - json with filters object
 */

/**
 * @event click.zg.getProductInfo.data-api Click to request new data (like for quick buy)
 * @type {null}
 */

/**
 * @event UpdateProductInfo.zg.getProductInfo.data-api do a new AJAX request updated with new data attributes, from event
 * @type {null}
 */



(function ( $, _ ) {
	'use strict';

	// Establish the root object ('window' in the browser)
	var root = this;

	/**
	 * @selector data-zg-role="get-product" The plugin start if there is the selector in the dom when the page load, or specific request
	 */
	var SELECTOR = '[data-zg-role="get-product"]';


	// url for the AJAX request
	var REQUEST_URL = root.makeUrl( { module: 'eshop', manager: 'eshop', action: 'getProductsInfo' } );

	/**
	 * AJAX CALL VALUES
	 *
	 * Enable the following options to request additional information for the product
	 * Some of this options allow you to select a specific value to request.
	 * Example:
	 *     getCharacteristics -> true  would request all characteristics
	 *     getCharacteristics -> x,y   would only get the information for the characteristics with ids x & y
	 */

	/**
	 * @param {string|int} [categoryId] Category id if you want display list of products of specific category
	 * @param {string} [products] List of products id, if you want display specific products
	 * @param {boolean} [forceResult] Forces to get the product info even if the shop configuration would prevent it
	 * @param {boolean} [getCategories] If you want category associated in the object of each product
	 * @param {string|boolean} [getCharacteristics] If you want caratteristics of each single product
	 * @param {boolean} [getClassification]  If you want classification of each single product
	 * @param {boolean} [getCustomValues]  If you want custom value of each single product
	 * @param {boolean} [getDescriptions]  If you want descriptions of each single product
	 * @param {string|boolean} [getImages]  If you want images of each single product
	 * @param {boolean} [getImages360]  If you want the 360 image view of each single product
	 * @param {boolean} [getLinkedProducts]  If you want linked product of each single product
	 * @param {boolean} [getNextPrev]  If you want next and prev product of each single product
	 * @param {string|boolean} [getOptions]  If you want options of each single product
	 * @param {boolean} [getQuantity]  If you want warehouse quantity of each single product
	 * @param {boolean} [getPrice]  If you want price of each single product
	 * @param {boolean} [getPricePerCountry]  If you want price for country of each single product
	 * @param {boolean} [getPromotions]  If you want promotions associated of each single product
	 * @param {boolean} [getPhysicalCharacteristics]
	 * @param {boolean} [getProperties]
	 * @param {boolean} [getSeo] get the SEO url
	 * @param {boolean} [getSkus]
	 * @param {boolean} [getStandardOptions]
	 * @param {boolean} [getTextile] If you want textile declaration for each single product
	 * @param {boolean} [getUrl]
	 * @param {boolean} [getFilterCategories] If you want filter by categories
	 * @param {boolean} [getFilterCharacteristics] If you want filter by characteristics
	 * @param {boolean} [getFilterClassification] If you want filter by classification
	 * @param {boolean} [getFilterOptions] If you want filter by options
	 * @param {boolean} [getFilterProperties] If you want filter by proprieties
	 * @param {boolean} [getFilterPrice] If you want filter by price
	 * @param {boolean} [getFilterPromotions] If you want filter by promotion
	 */
	var DEFAULT_REQUEST = {
		categoryId: null,
		products: null,
		// You could use this to force a language in the result
		// lang: false,
		forceResult: false,
		getCategories: true,
		getCharacteristics: false,
		getClassification: false,
		getCustomValues: false,
		getDescriptions: false,
		getImages: false,
		getImages360: false,
		getLinkedProducts: false,
		getNextPrev: false,
		getOptions: false,
		getQuantity: false,
		getPrice: false,
		getPricePerCountry: false,
		getPromotions: false,
		getPhysicalCharacteristics: false,
		getProperties: false,
		getSeo: false,
		getSkus: false,
		getStandardOptions: false,
		getTextile: false,
		getUrl: false,
		getFilterCategories: false,
		getFilterCharacteristics: false,
		getFilterClassification: false,
		getFilterOptions: false,
		getFilterProperties: false,
		getFilterPrice: false,
		getFilterPromotions: false
	};


	/**
	 * SCRIPT OPTIONS
	 *
	 * @type {{
	 *     type:                 string,
	 *     namespace:            string|null,
	 *     productsTemplate:     string|null,
	 *     errorTemplate:        string,
	 *     productsContainer:    null,
	 *     filterContainer:      string,
	 *     paginationContainer:  string,
	 *     empty:                boolean
	 *     }}
	 */

	/**
	 * @param {string} [type]  internal options
	 * @param {string} [namespace] namespace (for more than one request in one single page)
	 * @param {string} [productsTemplate] handlebars template to create the items
	 * @param {string} [errorTemplate] handlebars template for the item to be created if the request fails
	 * @param {string} [productsContainer] target element where the items will be added. if its empty it will try to use the current element
	 * @param {string} [filterContainer] The filter plugin will be initialized here if we requested filters
	 * @param {string} [paginationContainer] The pagination plugin will be initialized here if we didn't request filters and we specify a 'productsTemplate' option
	 * @param {boolean} [empty] Empty the container before rendering the items
	 */

	var DEFAULTS = {
		type: 'product',
		namespace: null,
		productsTemplate: null,
		errorTemplate: 'product-error',
		productsContainer: null,
		filterContainer: '[data-zg-role="filters"]',
		paginationContainer: '[data-zg-role="pagination"]',
		empty: true
	};


	// GETPRODUCT CLASS DEFINITION
	// ===========================

	/**
	 * Constructor function for GetProductInfo
	 *
	 * @param {HTMLElement} element
	 * @param {!Object}     options
	 *
	 * @constructor
	 */
	var GetProductInfo = function ( element, options ) {

		this.$element = $( element );

		this.options = {};
		this.request = {};
		this.updateOptions( options );

		// container to render the products.
		// if the option 'productsContainer' is not set it will use the original element
		this.$container = this.options.productsContainer ? $( this.options.productsContainer ) : this.$element;

		// responses cache.
		this.cached = {};
		this.latestResponse = {};



	};


	/**
	 * Returns the last response we got from the server for this instance of getProductInfo.
	 *
	 * @returns {Object}
	 */
	GetProductInfo.prototype.getLatestResponse = function () {
		return this.latestResponse;
	};


	/**
	 * Request the product information from the server.
	 * It uses the request data to generate a cache object, so the same request is not executed
	 * twice.
	 * The cache is created as a js object, reloading the page will clean the cache
	 *
	 */
	GetProductInfo.prototype.getInfo = function () {
		var cacheKey;

		

		if ( this.request.categoryId || this.request.products ) {
			cacheKey = JSON.stringify( this.request );

			if ( this.cached[cacheKey] ) {
				this.__onBeforeSend();
				this.__onSuccess( this.cached[cacheKey] );
				this.__onComplete();
			} else {
				$.ajax( {
					type:     'POST', // 'GET' breaks on the search version (too many parameters)
					url:      REQUEST_URL,
					dataType: 'json',
					data:     this.request,

					beforeSend: function () {
						this.__onBeforeSend();
					}.bind( this ),

					success: function ( response ) {
						this.__onSuccess( response );
						this.cached[cacheKey] = response;
					}.bind( this ),

					error: function ( response ) {
						this.__onError( response );
					}.bind( this ),

					complete: function () {
						this.__onComplete();
					}.bind( this )
				} );
			}
		} else if ( DEBUG ) {
			console.warn( 'GetProductInfo.getInfo - not requested: "categoryId" and "products" missing' );
		}
	};


	/**
	 * Initialize the filter plugin.
	 * Only used if we requested filters.
	 *
	 * @param {Array}  [filters]
	 * @param {Array}  [products]
	 * @param {string} [url]
	 *
	 * @private
	 */
	GetProductInfo.prototype.__createFilters = function ( filters, products, url ) {
		var $filterContainer = $( this.options.filterContainer );

		if ( $filterContainer.length > 1 ) {
			$filterContainer = $( this.options.filterContainer, this.$element );
		}

		$filterContainer.zg_filters( this.options, filters, products, url );
	};


	/**
	 * Destroy the previous products in the container if the 'empty' option is true and tries to
	 * unset the event listeners.
	 * Sends the product information to renderProducts.
	 * Only used if we didn't request filters and we specified the option 'productsTemplate'
	 *
	 * @param {Array} products
	 *
	 * @private
	 */
	GetProductInfo.prototype.__createProducts = function ( products ) {
		if ( this.options.empty ) {
			// remove the product events
			this.$container.find( '[data-zg-role="product"]' ).each( function () {
				var productData = $( this ).data( 'zg.product' );

				if ( productData ) {
					$( document ).off( '.' + productData.options.namespace );
				}

				// TODO: destroy zoom
			} );

			this.$container.empty();
		}

		this.__renderProducts( products );
	};


	/**
	 * Render the products we received using the handlebars template specified in 'productsTemplate'
	 *
	 * @param {Array} products
	 *
	 * @private
	 */

	/**
	 * @method __renderProducts
	 * @fires document#zg.getProductInfo.productCreated Products rendered using the handlebars template specified in 'productsTemplate'
	 */
	GetProductInfo.prototype.__renderProducts = function ( products ) {

		_.each( products || [], function ( product ) {
			var $item = $( handlebarsTemplates.render( this.options.productsTemplate, product ) );

			this.$container.append( $item );

			// Fade-in in using bootstrap classes.
			setTimeout(
				function () {
					$item.addClass( 'in' );
				},
				150
			);

			if ( DEBUG ) {
				console.log( 'GetProductInfo - renderProducts', product );
			}

			$( document ).trigger( 'zg.getProductInfo.productCreated', [$item, this.options, product] );
		}, this);
	};


	/**
	 * Check if we have requested filters from the server.
	 *
	 * @returns {boolean}
	 *
	 * @private
	 */
	GetProductInfo.prototype.__hasRequestedFilters = function () {
		return _.some( this.request, function ( elem, key ) {
			return ( key.indexOf( 'getFilter' ) === 0 );
		} );
	};


	/**
	 * Execute before sending the AJAX request.
	 * Sets the container as 'loading'.
	 *
	 * @private
	 */

	/**
	 * @method __onBeforeSend
	 * @fires document#zg.getProductInfo.start Execute before sending the AJAX request.
	 */
	GetProductInfo.prototype.__onBeforeSend = function () {
		this.$container.addClass( 'loading' );

		$( document ).trigger( 'zg.getProductInfo.start' );
	};


	/**
	 * Completed AJAX request.
	 * Removes the 'loading' class from the container.
	 *
	 * @private
	 */

	/**
	 * @method __onComplete
	 * @fires document#zg.getProductInfo.complete Completed AJAX request.
	 */
	GetProductInfo.prototype.__onComplete = function () {
		this.$container.removeClass( 'loading' );

		$( document ).trigger( 'zg.getProductInfo.complete' );document.dispatchEvent(categoryFilled);
	};


	/**
	 * Failed AJAX request.
	 * Empties the container if the option is set and renders the error template
	 *
	 * @param {Object} response
	 *
	 * @private
	 */

	/**
	 * @method __onError
	 * @fires document#zg.getProductInfo.error AJAX request make an error
	 */

	/**
	 * @method __onError
	 * @fires document#zg-error Display error message if ajax request make an error
	 */
	GetProductInfo.prototype.__onError = function ( response ) {
		if ( DEBUG ) {
			console.log( 'GetProductInfo - ERROR', response );
		}

		if ( this.options.empty ) {
			this.$container.empty();
		}

		// if there is an error template we add it in the target. otherwise we display an error message
		if ( this.options.errorTemplate ) {
			this.$container.append(
				handlebarsTemplates.render( this.options.errorTemplate, response || {} )
			);
		} else {
			$( document ).trigger( 'zg-error', [{
				message: root.JS_TRANSLATIONS.genericErrorMsg
			}] );
		}

		$( document ).trigger( 'zg.getProductInfo.error' );
	};


	/**
	 * Successful AJAX request.
	 * Process the product information.
	 *
	 * Sends the product (and filters) information to the next step (see below)
	 *
	 * @param {Object} response
	 *
	 * @private
	 */

	/**
	 * @method __onSuccess
	 * @fires document#zg.getProductInfo.success On ajax call success, products and filters are rendered
	 */
	GetProductInfo.prototype.__onSuccess = function ( response ) {
		var $pagination;

		

		if ( DEBUG ) {
			console.log( 'GetProductInfo - SUCCESS', response );
		}

		// Update each product in the array with some extra info
		if ( response.products ) {
			this.__processProducts( response.products ); 
		}

		if ( response.filters || this.__hasRequestedFilters() ) {
			// If we requested filters we send the product information to the the filter plugin.
			// Let the filters take over.
			this.__createFilters( response.filters, (response.products || []), response.url ); 
		} else if ( response.products && this.options.productsTemplate ) {
			// If a template was set up for the products we render them.
			this.__createProducts( response.products ); 
		} else {
			// Fallback: use the pagination plugin to render the products
			$pagination = $( this.options.paginationContainer, this.$element ); 

			if ( !$pagination.length ) {
				$pagination = this.$container; 
			}

			$pagination.zg_pagination( this.options, response.products || [] ); 
		}

		// Store the latest response.
		this.latestResponse = response;

		$( document ).trigger( 'zg.getProductInfo.success', [response.products, response.filters] );
	};


	/**
	 * Process the products we received form the server.
	 *
	 * @param {Array} products
	 * @private
	 */
	GetProductInfo.prototype.__processProducts = function ( products ) {
		var counter = 1;
		_.each( products || [], function ( product ) {
			// set the type of the requested product
			product.type = this.options.type;
			product.keyNumber = counter;
			counter++;

			if ( this.request.categoryId ) {
				product.currentCategory = this.request.categoryId;
			}
		}, this );
		
	};


	/**
	 * Process the request object to remove unnecessary information.
	 * We go through all properties in the request and remove the falsy ones and any not present
	 * in the original DEFAULT_REQUEST
	 *
	 * @param {Object} config
	 * @private
	 */
	GetProductInfo.prototype.__processRequest = function ( config ) {
		var param;
		var value;
		var request = {};

		for ( param in DEFAULT_REQUEST ) {
			// Filter by values by the default properties and not falsy values.
			// We don't want to create an unnecessarily big request
			if (
				DEFAULT_REQUEST.hasOwnProperty( param ) &&
				config.hasOwnProperty( param ) &&
				config[param] // only truthy values
			) {
				value = config[param];

				// if the value is a string split into array (necessary for backend).
				// This has to happen even if there is just one value.
				if ( _.isString( value ) ) {
					value = value.split( ',' );
				}

				request[param] = value;
			}
		}

		this.request = request;
	};


	/**
	 * Update the options and request properties with the an options object
	 *
	 * @param {Object} [options]
	 *
	 * @private
	 */
	GetProductInfo.prototype.updateOptions = function ( options ) {
		this.options = _.extendOwn( {}, DEFAULTS, this.options, options || {} );
        this.request = _.extendOwn( {}, DEFAULT_REQUEST, this.request, options || {} );
		this.__processRequest(this.request || {});
	};


	// GETPRODUCT PLUGIN DEFINITION
	// ============================

	/**
	 *
	 * @param {Object}  [option]
	 * @param {boolean} [updateOptions]
	 * @returns {*}
	 */
	function Plugin ( option, updateOptions ) {
		return this.each( function () {
			var $this = $( this );
			var data = $this.data( 'zg.getProductInfo' );
			var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.getProductInfo', (data = new GetProductInfo( this, options )) );
			} else if ( updateOptions && typeof option === 'object' ) {
				data.updateOptions( option );
			}

			data.getInfo();
		} );
	}

	$.fn.getProductInfo = Plugin;
	$.fn.getProductInfo.Constructor = GetProductInfo;


	// GETPRODUCT DATA-API
	// ===================

	// default product - called on page load

	/**
	 * @method document
	 * @listen click.zg.getProductInfo.data-api Do a new AJAX request updated with new data attributes - from click
	 */

	/**
	 * @method document
	 * @listen UpdateProductInfo.zg.getProductInfo.data-api Do a new AJAX request updated with new data attributes - from event
	 */

	/**
	 * @method document
	 * @listen click.zg.getProductInfo.data-api quickbuy on click of data-zg-role~="quickbuy"
	 */

	/**
	 * @method document
	 * @listen click.zg.getProductInfo.data-api return && edit cart on click of data-zg-role~="quickedit"
	 */

	$( function () {
		$( SELECTOR ).each( function () {
			Plugin.call( $( this ) );
		} );

		$( SELECTOR ).on( 'click.zg.getProductInfo.data-api', '[data-zg-role="update-product-info"]', function ( e ) {
			var $this = $( this );

			Plugin.call( $this.closest( SELECTOR ), $this.data(), true );

			e.preventDefault();
		} );

		$( SELECTOR ).on( 'UpdateProductInfo.zg.getProductInfo.data-api', function ( e, data ) {
			Plugin.call( $( this ), data, true );
		} );

		$( document ).on( 'click.zg.getProductInfo.data-api', '[data-zg-role~="quickbuy"]', function ( e ) {
			Plugin.call( $( this ), { type: 'quickbuy' } );

			e.preventDefault();
		} );

		$( document ).on( 'click.zg.getProductInfo.data-api', '[data-zg-role~="quickedit"]', function ( e ) {
			Plugin.call( $( this ), { type: 'exchange' } );

			e.preventDefault();
		} );
	} );

}.call( this, jQuery, _ ));
